
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.fias-search-view {
  &__wrapper {
    display: flex;
  }
}
